import { graphql } from "gatsby";
import React from "react";
import { Query } from "..";
import Slice from "../components/Elements/Slice";
import SoundcloudPlaylist from "../components/Elements/SoundcloudPlaylist";
import Page from "../components/Layout/Page";
import pageColors from "../data/pageColors";
import pageTitles from "../data/pageTitles";
import PageHeader from "../components/Elements/PageHeader";
import ExternalLink from "../components/Elements/ExternalLink";

const Music = ({ data }: { data: Query }) => (
    <Page title={pageTitles.music}>
        <PageHeader
            color={pageColors.music}
            counts={[`${data.allSoundcloudtrack.totalCount} tracks`]}
        >
            <p>
                I started learning guitar by myself when I was still a teenager,
                after playing too much Guitar Hero and dreaming of actually
                playing. I kept learning for a few years and experimenting with
                the instrument and loops/effects until came out of it a series
                of experimental "tracks", some of which came to become{" "}
                <strong>The Inbetweeners</strong>, my oldest collection of
                tracks.
            </p>
            <p>
                A couple of years later I started experimenting with creating
                "narrative" tracks using samples, a series of moderately long
                progressive tracks that each tried to tell a different story,
                which became the album{" "}
                <strong>Out Through the Winter Throat</strong>.
            </p>
            <p>
                Finally a few years later I decided to get back into music and
                bought a synthetizer to compose an electronic album using all
                the influences I had discovered since. Each track would still be
                very cinematic/narrative like in the previous album but they're
                all about things that have happened to me over the years, so
                it's a very autobiographical album even though it's purely
                instrumental.
            </p>
        </PageHeader>
        <div className="flex flex-col">
            {data.allSoundcloudplaylist.nodes.map((album) => (
                <Slice
                    key={album.id}
                    image={album.childrenFile[0].childImageSharp.fluid}
                    style={{ minHeight: 750 }}
                >
                    <header className="mb-8 text-right w-full p-10 rounded bg-black-75">
                        <h1 className="text-3xl xl:text-6xl font-hairline m-0 uppercase">
                            <ExternalLink href={album.permalink_url}>
                                {album.title}
                            </ExternalLink>
                        </h1>
                        <small className="uppercase font-bold">
                            {album.genre}, {album.release_year}
                        </small>
                        <p className={"mt-5"}>{album.description}</p>
                    </header>
                    <SoundcloudPlaylist tracks={album.tracks} />
                </Slice>
            ))}
        </div>
    </Page>
);

export const query = graphql`
    {
        allSoundcloudtrack {
            totalCount
        }
        allSoundcloudplaylist(sort: { fields: release_year, order: DESC }) {
            nodes {
                permalink_url
                artwork_url
                description
                genre
                id
                release_year
                soundcloud_id
                title
                childrenFile {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                tracks {
                    id
                    description
                    soundcloud_id
                    playback_count
                    title
                }
            }
        }
    }
`;

export default Music;
