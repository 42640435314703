import React, { Fragment } from "react";
import LazyLoad from "react-lazyload";

const SoundcloudPlaylist = ({ tracks }) => (
    <div className={"w-full lg:w-2/3 m-auto"}>
        {tracks.map((track, key) => (
            <div
                key={key}
                className={
                    "mb-10 p-5 my-5 rounded shadow-2xl bg-soundcloud transition text-white"
                }
            >
                <h2
                    className={`flex flex-col md:flex-row text-lg items-center font-hairline justify-around border-orange-700`}
                >
                    <span>{String(key + 1).padStart(3, "0")}</span>
                    <span className={"text-2xl font-medium"}>
                        {track.title}
                    </span>
                    <span>{track.playback_count} plays</span>
                </h2>
                {track.description && (
                    <p className={"mt-5 rounded p-3 bg-black-25"}>
                        {track.description.split("\n").map((item, key) => {
                            const [marker, name] = item.split(" - ");

                            return (
                                <Fragment key={key}>
                                    <strong>{marker}</strong> - {name}
                                    <br />
                                </Fragment>
                            );
                        })}
                    </p>
                )}
                <LazyLoad height={130} once offset={100} overflow>
                    <iframe
                        title={track.title}
                        width="100%"
                        height="130"
                        scrolling="no"
                        frameBorder="no"
                        allow="autoplay"
                        className={"mt-5"}
                        src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${track.soundcloud_id}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`}
                    />
                </LazyLoad>
            </div>
        ))}
    </div>
);

export default SoundcloudPlaylist;
